




import { Component, Vue } from 'vue-property-decorator';
import Mpr from './components/Mpr.vue';

@Component({
  components: {
    Mpr,
  },
})
export default class App extends Vue {}
