import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Module
import App from './App.vue'
import './scss/main.scss'

// Font Awesome
library.add(fas)
Vue.component('fa-icon', FontAwesomeIcon)

// Vue
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
